import React from 'react'
import {Link, graphql} from 'gatsby'
import upperFirst from 'lodash/upperFirst';

import Layout from '../components/Layout'
import PageHeading from "../components/PageHeading";
import ArticleColumns from "../components/ArticleColumns";

class Place extends React.Component {
    render() {
        const posts = this.props.data.allMarkdownRemark.edges;
        const label = upperFirst(this.props.pageContext.label);
        const description = upperFirst(this.props.pageContext.description);

        return (
            <Layout title={label} description={description}>
                <PageHeading heading={label} className="title is-size-3 is-size-4-touch is-size-4-mobile"/>
                <section className="section">
                    <div className="container content">
                        <ArticleColumns posts={posts}/>
                        <div className="column is-12 has-text-centered">
                            <Link className="btn" to="/places/">
                                Посмотреть все места
                            </Link>
                        </div>
                    </div>
                </section>
            </Layout>
        )
    }
}

export default Place;

export const placeByIDQuery = graphql`
    query PlaceByID($id: String) {
        allMarkdownRemark(
            sort: { fields: [frontmatter___date], order: DESC }
            filter: { frontmatter: { templateKey: { eq: "blog-post" }, places: { in: [$id] } } }
        ) {
            edges {
                node {
                    fields {
                        slug
                    }
                    frontmatter {
                        title
                        templateKey
                        date(formatString: "MMMM DD, YYYY")
                        featuredpost
                        featuredimage {
                            childImageSharp {
                                fluid(maxWidth: 340, maxHeight: 340, fit: COVER) {
                                    ...GatsbyImageSharpFluid_withWebp
                                }
                            }
                        }
                    }
                    excerpt
                    id
                }
            }
        }
    }
`;
