import React from "react";

import ArticleCard from "./ArticleCard";

const ArticleColumns = (props) => (
    <div className="columns is-multiline">
        {props.posts && props.posts.map(({node: post}) => <ArticleCard post={post} key={post.id}/>)}
    </div>
);

export default ArticleColumns;
